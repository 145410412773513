import React, { useEffect, useState } from "react";
import {
  deleteCategory,
  getCategoryList,
  getPhotoByProductGroupRef,
  saveCategory,
} from "../../../infrastructure/kangaroo_api";

import { PictureOutlined, PictureFilled } from "@ant-design/icons";

import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";
import NewCategory from "./new_category";
import ImageToBase64Converter from "../../common/image_to_base_64_converter";

const CategoryList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowKey, setEditRowKey] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [icon, setIcon] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [productGroupRef, setProductGroupRef] = useState(0);

  let [filteredData] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getCategoryList().then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const isEditing = (record) => {
    return record.id == editRowKey;
  };

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      record = { ...record, ...row };
      await saveCategory(record);

      await loadData();
      setEditRowKey("");
    } catch {}
  };

  const edit = (record) => {
    form.setFieldsValue({
      categoryName: "",
      ...record,
    });
    setEditRowKey(record.id);
  };

  const handleDelete = async (value) => {
    console.log("Deleting");
    await deleteCategory(value);
    await loadData();
  };

  const showIcon = async (productGroup) => {
    setProductGroupRef(productGroup.id);

    var data = await getPhotoByProductGroupRef(productGroup);

    var d = data.find((element) => {
      return element.photoType === 0;
    });
    console.log(d);
    if (d !== undefined) {
      console.log("d is ok");
      setIcon(d.data);
    } else {
      setIcon(null);
    }

    setOpen(2);
  };

  const showSelectedIcon = async (productGroup) => {
    setProductGroupRef(productGroup.id);

    var data = await getPhotoByProductGroupRef(productGroup);
    var d = data.find((element) => {
      return element.photoType === 1;
    });
    if (d !== undefined) {
      setSelectedIcon(await d.data);
    } else {
      setSelectedIcon(null);
    }
    setOpen(3);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Kategory Adı",
      dataIndex: "categoryName",
      editable: true,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === "categoryName" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary" disabled={editable}>
                Sil
              </Button>
            </Popconfirm>
            {editable ? (
              <span>
                <Space size="middle">
                  <Button
                    onClick={(e) => save(record)}
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    Kaydet
                  </Button>
                  <Popconfirm title="Vazgeç ? " onConfirm={cancel}>
                    <Button>Vazgeç</Button>
                  </Popconfirm>
                </Space>
              </span>
            ) : (
              <Button onClick={() => edit(record)} type="primary">
                Güncelle
              </Button>
            )}
            <Button onClick={() => showIcon(record)} type="default">
              {<PictureOutlined />}
            </Button>
            <Button onClick={() => showSelectedIcon(record)} type="default">
              {<PictureFilled />}
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) => {
    const input = <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Lütfen geçerli bir değer giriniz ${title}`,
              },
            ]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loadData();
    }
  };

  const globalSearch = (e) => {
    filteredData = data.filter((value) => {
      return value.deviceId
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  const addNew = () => {
    setOpen(1);
  };

  const handleOk = async (values) => {
    if (values.categoryName === undefined) {
      return;
    }

    setConfirmLoading(true);
    saveCategory({ ...values }).then((result) => {
      console.log(result);
      setConfirmLoading(false);
      setOpen(0);
      loadData();
    });
  };

  const handleCancel = (e) => {
    console.log("canceled");
    setOpen(0);
  };

  return (
    <div>
      <Modal
        title="Yeni Kategori"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <NewCategory onOk={handleOk} onCancel={handleCancel}></NewCategory>
      </Modal>
      <Modal
        title="İkon"
        open={open == 2}
        footer={null}
        onCancel={(e) => {
          setOpen(0);
        }}
      >
        <ImageToBase64Converter
          photo={{ data: icon, photoType: 0, productGroupRef: productGroupRef }}
        />
      </Modal>
      <Modal
        title="Seçili İkon"
        open={open == 3}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ImageToBase64Converter
          photo={{
            data: selectedIcon,
            photoType: 1,
            productGroupRef: productGroupRef,
          }}
        />
      </Modal>

      <Space style={{ marginBottom: 16, marginTop: 16 }}>
        <Button onClick={addNew} type="primary">
          Yeni Kategori
        </Button>
        Filtre :
        <Input
          placeholder="Filtre"
          onChange={handleInputChange}
          type="text"
          allowClear
          value={searchText}
        />
        <Button onClick={globalSearch} type="primary">
          Ara
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={filteredData && filteredData.length ? filteredData : data}
          bordered
          loading={loading}
          onChange={handleChange}
          rowKey="id"
        />
      </Form>
    </div>
  );
};

export default CategoryList;
