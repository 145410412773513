import React from "react";
import { Form, Input, Space, Button, InputNumber, Select } from "antd";
const { TextArea } = Input;

const ProductForm = ({
  onChange,
  fields,
  onCancel,
  onOK,
  categoryItems,
  departments,
}) => {
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="product_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Form.Item
        name="productCode"
        label="Stok Kodu"
        rules={[
          {
            required: true,
            message: "Stok Kodu alanı zorunludur!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="productName"
        label="Stok Açıklama"
        rules={[
          {
            required: true,
            message: "Stok Açıklama alanı zorunludur!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Açıklama">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="categoryRef" label="Stok Grup ">
        <Select options={categoryItems} />
      </Form.Item>
      <Form.Item name="salePrice1" label="Satış Fiyatı 1">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="salePrice2" label="Satış Fiyatı 2">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="orderNo" label="Sıra No">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="departmentRef" label="Departman">
        <Select options={departments} />
      </Form.Item>
      <Form.Item name="vatRate" label="Kdv Oranı">
        <InputNumber min={0} />
      </Form.Item>

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default ProductForm;
