import React, { useState } from "react";
import { savePhoto } from "../../infrastructure/kangaroo_api";

const ImageToBase64Converter = ({ photo }) => {
  const [base64String, setBase64String] = useState(photo.data);
  const [selectedImage, setSelectedImage] = useState(photo.data);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setBase64String(reader.result);
        setSelectedImage(URL.createObjectURL(file));

        photo = { ...photo, id: 0, data: reader.result };
        await savePhoto(photo);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />

      {photo.data && (
        <div>
          <img
            src={photo.data}
            alt="Selected"
            style={{ maxWidth: "300px", maxHeight: "300px" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageToBase64Converter;
