import React, { useEffect, useState, useRef } from "react";
import { Button, Table, Space, DatePicker, Tag } from "antd";
import { getLines, getList } from "../../../infrastructure/kangaroo_api";

const InvoiceDetailForm = ({ lines }) => {
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "productCode",
      align: "left",
      sorter: (a, b) => a.productCode.localeCompare(b.productCode),
      sortOrder: sortedInfo.columnKey === "productCode" && sortedInfo.order,
    },
    {
      title: "Stok Açıklama",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === "productName" && sortedInfo.order,
    },
    {
      title: "Not",
      dataIndex: "lineNote",
      sorter: (a, b) => a.lineNote.localeCompare(b.lineNote),
      sortOrder: sortedInfo.columnKey === "lineNote" && sortedInfo.order,
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      sorter: (a, b) => a.amount > b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
    },
    {
      title: "Birim Fiyat",
      dataIndex: "price",
      sorter: (a, b) => a.unitPrice > b.unitPrice,
      sortOrder: sortedInfo.columnKey === "unitPrice" && sortedInfo.order,
    },
    {
      title: "Tutar",
      dataIndex: "total",
      sorter: (a, b) => a.total > b.total,
      sortOrder: sortedInfo.columnKey === "total" && sortedInfo.order,
    },
    {
      title: "Extra Tutarı",
      dataIndex: "extraTotal",
      sorter: (a, b) => a.extraTotal > b.extraTotal,
      sortOrder: sortedInfo.columnKey === "extraTotal" && sortedInfo.order,
    },

    {
      title: "Toplam",
      dataIndex: "lineTotal",
      sorter: (a, b) => a.lineTotal > b.lineTotal,
      sortOrder: sortedInfo.columnKey === "lineTotal" && sortedInfo.order,
    },
  ];
  return (
    <Space>
      <Table columns={columns} dataSource={lines} bordered rowKey="id" />
    </Space>
  );
};

export default InvoiceDetailForm;
