import React, { useEffect, useState } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, Space, Form, Input, Modal } from "antd";
import { getList, save } from "../../../infrastructure/kangaroo_api";

const ProductExtraList = ({ productRef }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);

  let [filteredData] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, [productRef]);

  const loadData = async () => {
    setLoading(true);
    var result = await getList(`product/productExtra/${productRef}`);
    console.log(result);
    setLoading(false);
    setData(result);
  };

  const addExtra = async (record) => {
    setLoading(true);
    var addResponse = await save("product/productExtra/add", record);
    setData(addResponse);
    setLoading(false);
  };

  const removeExtra = async (record) => {
    setLoading(true);
    var removeResponse = await save("product/productExtra/remove", record);
    setData(removeResponse);
    setLoading(false);
  };
  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const columns = [
    {
      title: "Extra Kodu",
      dataIndex: "extraCode",
      sorter: (a, b) => a.extraCode.localeCompare(b.extraCode),
      sortOrder: sortedInfo.columnKey === "extraCode" && sortedInfo.order,
    },
    {
      title: "Extra Açıklama",
      dataIndex: "extraName",
      sorter: (a, b) => a.extraName.localeCompare(b.extraName),
      sortOrder: sortedInfo.columnKey === "extraName" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        console.log(record);
        return data.length >= 1 ? (
          record.selected == true ? (
            <Space>
              <Button onClick={() => removeExtra(record)} type="default">
                {<MinusOutlined />}
              </Button>
            </Space>
          ) : (
            <Button onClick={() => addExtra(record)} type="default">
              {<PlusOutlined />}
            </Button>
          )
        ) : null;
      },
    },
  ];

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loadData();
    }
  };

  const globalSearch = (e) => {
    filteredData = data.filter((value) => {
      return value.productName.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Extra Listesi"
        open={open == 3}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      ></Modal>

      <Space style={{ marginBottom: 16, marginTop: 16 }}>
        Filtre :
        <Input
          placeholder="Filtre"
          onChange={handleInputChange}
          type="text"
          allowClear
          value={searchText}
        />
        <Button onClick={globalSearch} type="primary">
          Ara
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={filteredData && filteredData.length ? filteredData : data}
          bordered
          loading={loading}
          rowKey="id"
        />
      </Form>
    </div>
  );
};

export default ProductExtraList;
