import React, { useEffect, useState, useRef } from "react";
import { Button, Table, Space, DatePicker, Tag, Modal } from "antd";
import { getLines, getList } from "../../../infrastructure/kangaroo_api";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import InvoiceDetailForm from "./invoice_detail_form";

const InvoiceList = () => {
  const [data, setData] = useState([]);
  const [lines, setLines] = useState([]);

  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rowCount, setRowCount] = useState(0);
  const [totalCash, setTotalCash] = useState(0.0);
  const [totalCredit, setTotalCredit] = useState(0.0);

  const [open, setOpen] = useState(0);

  let startDateState = useRef();
  let endDateState = useRef();

  let [filteredData] = useState();
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    var newDate = dayjs(new Date());

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    startDateState.current = new Date(year, month, day).getTime();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();

    loadData(
      new Date(year, month, day).getTime(),
      new Date(year, month, day, 23, 59, 59).getTime()
    );
  }, []);

  const loadData = async (sDate, eDate) => {
    setLoading(true);
    console.log(sDate);
    console.log(eDate);

    getList(`report/sale/1/${sDate}/${eDate}`).then((result) => {
      var cash = 0.0,
        credit = 0.0;

      var recordCount = 0;

      result.map((el) => {
        let date = new Date(el.invoiceDate);
        el.invoiceDate =
          date.toLocaleDateString() + " - " + date.toLocaleTimeString();
        cash += el.cashTotal;
        credit += el.creditTotal;
        recordCount++;
      });

      setRowCount(recordCount);
      setTotalCash(cash);
      setTotalCredit(credit);
      setData(result);
      setLoading(false);
    });
  };

  const globalSearch = async (e) => {
    await loadData(startDateState.current, endDateState.current);
    setData(data);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const details = async (record) => {
    console.log(record);
    var invoiceLines = await getLines(record.id);
    console.log(invoiceLines);
    setLines(invoiceLines);
    setOpen(1);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Tarih",
      dataIndex: "invoiceDate",
      sorter: (a, b) => a.invoiceDate > b.invoiceDate,
      sortOrder: sortedInfo.columnKey === "invoiceDate" && sortedInfo.order,
    },
    {
      title: "Fiş Toplamı",
      dataIndex: "ficheTotal",
      align: "center",
      width: 320,
      render: (text, record) => (
        <Space>
          <Tag style={{ width: 80, textAlign: "center" }} color="green">
            {record.cashTotal.toFixed(2)} (N)
          </Tag>

          <Tag style={{ width: 80, textAlign: "center" }} color="orange">
            {record.creditTotal.toFixed(2)} (KK)
          </Tag>

          <Tag style={{ width: 80, textAlign: "center" }}>
            {(record.cashTotal + record.creditTotal).toFixed(2)} (T)
          </Tag>
        </Space>
      ),
      sorter: (a, b) => a.ficheTotal > b.ficheTotal,
      sortOrder: sortedInfo.columnKey === "ficheTotal" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Button onClick={() => details(record)} type="primary">
              Detaylar
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const onStartDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    //setStartDate(new Date(year, month, day));
    startDateState.current = new Date(year, month, day).getTime();
  };

  const onEndDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();
  };

  return (
    <div>
      <Modal
        title="Detaylar"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <InvoiceDetailForm lines={lines} />
      </Modal>

      <Space>
        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(startDate)}
          onChange={onStartDateChange}
        />

        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(endDate)}
          onChange={onEndDateChange}
        />

        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag style={{ width: 80, textAlign: "center" }} color="red">
          ({rowCount})
        </Tag>

        <Tag style={{ width: 80, textAlign: "center" }} color="green">
          {totalCash.toFixed(2)} (N)
        </Tag>

        <Tag style={{ width: 80, textAlign: "center" }} color="orange">
          {totalCredit.toFixed(2)} (KK)
        </Tag>

        <Tag style={{ width: 80, textAlign: "center" }}>
          {(totalCash + totalCredit).toFixed(2)} (T)
        </Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};

export default InvoiceList;
