import React, { useEffect, useState } from "react";
import {
  deleteOptionSet,
  getOptionSetItemList,
  getOptionSetList,
  getPhotoByProductGroupRef,
  saveOptionSet,
} from "../../../infrastructure/kangaroo_api";

import { UnorderedListOutlined } from "@ant-design/icons";

import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";

import OptionSetForm from "./option_set_form";
import OptionSetItemList from "../option_set_item/option_set_item_list";

const OptionSetList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowKey, setEditRowKey] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [icon, setIcon] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedOptionSetRef, setSelectedOptionSetRef] = useState(0);
  const [optionSet, setOptionSet] = useState();
  const [optionSetItems, setOptionSetItems] = useState([]);

  const [fields, setFields] = useState([
    {
      name: ["optionSetName"],
      value: "",
    },
    {
      name: ["id"],
      value: 0,
    },
  ]);

  let [filteredData] = useState();

  const [form] = Form.useForm();

  const [modal] = Modal.useModal();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getOptionSetList().then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const isEditing = (record) => {
    return record.id == editRowKey;
  };

  const cancel = () => {
    setEditRowKey("");
  };

  const handleOk = async (values) => {
    if (values.optionSetName === undefined) {
      return;
    }

    setConfirmLoading(true);
    save({ ...values }).then((result) => {
      setConfirmLoading(false);
      setOpen(0);
    });
  };

  const handleCancel = (e) => {
    console.log("canceled");
    setOpen(0);
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      record = { ...record, ...row };
      await saveOptionSet(record);
      await loadData();
      setEditRowKey("");
    } catch {}
  };

  const addNew = () => {
    setFields([
      {
        name: ["optionSetName"],
        value: "",
      },
      {
        name: ["id"],
        value: 0,
      },
    ]);
    setOpen(1);
  };

  const edit = (record) => {
    console.log(record);
    setFields([
      {
        name: ["optionSetName"],
        value: record.optionSetName,
      },
      {
        name: ["id"],
        value: record.id,
      },
    ]);
    setOpen(1);
  };

  const handleDelete = async (value) => {
    await deleteOptionSet(value);
    await loadData();
  };

  const showOptionItems = async (optionSet) => {
    var items = await getOptionSetItemList(optionSet);
    console.log(items);
    setOptionSet(optionSet);
    setOptionSetItems(items);

    setOpen(2);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Opsiyon Listesi Adı",
      dataIndex: "optionSetName",
      editable: true,
      sorter: (a, b) => a.optionSetName.localeCompare(b.optionSetName),
      sortOrder: sortedInfo.columnKey === "optionSetName" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary" disabled={editable}>
                Sil
              </Button>
            </Popconfirm>
            {editable ? (
              <span>
                <Space size="middle">
                  <Button
                    onClick={(e) => save(record)}
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    Kaydet
                  </Button>
                  <Popconfirm title="Vazgeç ? " onConfirm={cancel}>
                    <Button>Vazgeç</Button>
                  </Popconfirm>
                </Space>
              </span>
            ) : (
              <Button onClick={() => edit(record)} type="primary">
                Güncelle
              </Button>
            )}
            <Button onClick={() => showOptionItems(record)} type="default">
              {<UnorderedListOutlined />}
              Seçenekler
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.optionSetName,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) => {
    const input = <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Lütfen geçerli bir değer giriniz ${title}`,
              },
            ]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loadData();
    }
  };

  const globalSearch = (e) => {
    filteredData = data.filter((value) => {
      return value.optionSetName
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Yeni Opsiyon Listesi"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <OptionSetForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>
      <Modal
        title="Seçenekler"
        open={open == 2}
        footer={null}
        onCancel={(e) => {
          setOpen(0);
        }}
      >
        <OptionSetItemList
          optionSet={optionSet}
          optionSetItems={optionSetItems}
        />
      </Modal>
      <Space style={{ marginBottom: 16, marginTop: 16 }}>
        <Button onClick={addNew} type="primary">
          Yeni Opsiyon Listesi
        </Button>
        Filtre :
        <Input
          placeholder="Filtre"
          onChange={handleInputChange}
          type="text"
          allowClear
          value={searchText}
        />
        <Button onClick={globalSearch} type="primary">
          Ara
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={filteredData && filteredData.length ? filteredData : data}
          bordered
          loading={loading}
          onChange={handleChange}
          rowKey="id"
        />
      </Form>
    </div>
  );
};

export default OptionSetList;
