import React, { useState } from "react";
import { Form, Input, Space, Button, InputNumber } from "antd";

const OptionSetItemForm = ({ onChange, fields, onCancel, onOK }) => {
  return (
    <Form
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="option_set_item_form"
      layout="inline"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Space direction="vertical">
        <Form.Item
          name="setItemName"
          label="Opsiyon Adı"
          rules={[
            {
              required: true,
              message: "Opsiyon Adı zorunludur!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="Fiyat"
          rules={[
            {
              required: true,
              message: "Opsiyon Fiyat Alanı zorunludur!",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
          <Button type="primary" danger onClick={onCancel}>
            Vazgeç
          </Button>
        </Space>
      </Space>
    </Form>
  );
};
export default OptionSetItemForm;
