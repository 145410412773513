const newProductPrice = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["productName"],
    value: "",
  },
  {
    name: ["groupCode"],
    value: "",
  },
  {
    name: ["orderNo"],
    value: 0,
  },
  {
    name: ["unitName1"],
    value: "",
  },
  {
    name: ["unitName2"],
    value: "",
  },
  {
    name: ["unitName3"],
    value: "",
  },
  {
    name: ["unitPrice1"],
    value: 0,
  },
  {
    name: ["unitPrice2"],
    value: 0,
  },
  {
    name: ["unitPrice3"],
    value: 0,
  },
  {
    name: ["tvNumber"],
    value: 0,
  },

  {
    name: ["orderNo"],
    value: 0,
  },
];

export default newProductPrice;
