import React, { useState } from "react";
import { Form, Input, Space, Button, InputNumber } from "antd";

const OptionSetForm = ({ onChange, fields, onCancel, onOK }) => {
  return (
    <Form
      name="option_set_form"
      layout="horizontal"
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 14,
      }}
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Form.Item
        name="optionSetName"
        label="Opsiyon Listesi Adı"
        rules={[
          {
            required: true,
            message: "Opsiyon Listesi Adı zorunludur!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default OptionSetForm;
