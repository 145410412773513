import React, { useEffect, useState } from "react";
import { PictureFilled, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";
import {
  deleteProductPriceList,
  getProductPriceList,
  saveProductPrice,
} from "../../infrastructure/filed_sets/product_price_api";
import ProductPriceForm from "./product_price_form";
import newProductPrice from "../../infrastructure/filed_sets/NEW_PRODUCT_PRICE";

const ProductPriceList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  let [filteredData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [fields, setFields] = useState(newProductPrice);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getProductPriceList().then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const addNew = () => {
    setFields(newProductPrice);
    setOpen(1);
  };

  const edit = (record) => {
    setFields([
      {
        name: ["productName"],
        value: record.productName,
      },
      {
        name: ["groupCode"],
        value: record.groupCode,
      },
      {
        name: ["unitName1"],
        value: record.unitName1,
      },
      {
        name: ["unitName2"],
        value: record.unitName2,
      },
      {
        name: ["unitName3"],
        value: record.unitName3,
      },
      {
        name: ["unitPrice1"],
        value: record.unitPrice1,
      },
      {
        name: ["unitPrice2"],
        value: record.unitPrice2,
      },
      {
        name: ["unitPrice3"],
        value: record.unitPrice3,
      },
      {
        name: ["tvNumber"],
        value: record.tvNumber,
      },
      {
        name: ["id"],
        value: record.id,
      },
    ]);
    setOpen(1);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleOk = async (values) => {
    if (values.productName === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newProduct = await saveProductPrice(values);

    await loadData();
    setConfirmLoading(false);

    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const handleDelete = async (value) => {
    console.log(value);
    await deleteProductPriceList(value);
    await loadData();
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Sıra No",
      dataIndex: "orderNo",
      sorter: (a, b) => a.orderNo > b.orderNo,
      sortOrder: sortedInfo.columnKey === "orderNo" && sortedInfo.order,
    },
    {
      title: "Stok Açıklama",
      dataIndex: "productName",
      sorter: (a, b) => a.productCode.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === "productName" && sortedInfo.order,
    },
    {
      title: "Grup Kodu",
      dataIndex: "groupCode",
      sorter: (a, b) => a.groupCode.localeCompare(b.groupCode),
      sortOrder: sortedInfo.columnKey === "groupCode" && sortedInfo.order,
    },
    {
      title: "Birim(1)",
      dataIndex: "unitName1",
      sorter: (a, b) => a.description.localeCompare(b.unitName1),
      sortOrder: sortedInfo.columnKey === "unitName1" && sortedInfo.order,
    },
    {
      title: "Birim(2)",
      dataIndex: "unitName2",
      sorter: (a, b) => a.description.localeCompare(b.unitName2),
      sortOrder: sortedInfo.columnKey === "unitName2" && sortedInfo.order,
    },
    {
      title: "Birim(3)",
      dataIndex: "unitName3",
      sorter: (a, b) => a.description.localeCompare(b.unitName3),
      sortOrder: sortedInfo.columnKey === "unitName3" && sortedInfo.order,
    },
    {
      title: "Satış Fiyatı(1)",
      dataIndex: "unitPrice1",
      sorter: (a, b) => a.unitPrice1 > b.unitPrice1,
      sortOrder: sortedInfo.columnKey === "unitPrice1" && sortedInfo.order,
    },
    {
      title: "Satış Fiyatı(2)",
      dataIndex: "unitPrice2",
      sorter: (a, b) => a.unitPrice2 > b.unitPrice2,
      sortOrder: sortedInfo.columnKey === "unitPrice2" && sortedInfo.order,
    },
    {
      title: "Satış Fiyatı(3)",
      dataIndex: "unitPrice3",
      sorter: (a, b) => a.unitPrice3 > b.unitPrice3,
      sortOrder: sortedInfo.columnKey === "unitPrice3" && sortedInfo.order,
    },

    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>

            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <Modal
        title="Stok Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ProductPriceForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>
      <Button onClick={addNew} type="primary">
        Yeni Stok Kartı
      </Button>
      <Table
        columns={columns}
        dataSource={filteredData && filteredData.length ? filteredData : data}
        bordered
        loading={loading}
        rowKey="id"
      />
    </div>
  );
};

export default ProductPriceList;
