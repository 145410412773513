const newProduct = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["productCode"],
    value: "",
  },
  {
    name: ["productName"],
    value: "",
  },
  {
    name: ["description"],
    value: "",
  },
  {
    name: ["salePrice1"],
    value: 0,
  },
  {
    name: ["salePrice2"],
    value: 0,
  },
  {
    name: ["departmentRef"],
    value: 0,
  },
  {
    name: ["vatRate"],
    value: 0,
  },
  {
    name: ["orderNo"],
    value: 0,
  },
];

export default newProduct;
