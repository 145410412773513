import React from "react";

import { Form, Input, Button, Space } from "antd";

const NewCategory = ({ onOk, onCancel }) => {
  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onOk}
      autoComplete="off"
    >
      <Form.Item
        label="Kategori Adı"
        name="categoryName"
        rules={[
          {
            warningOnly: true,
            required: true,
            message: "Lütfen geçerli bir kategori adı giriniz...",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Space>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
          <Button type="primary" danger onClick={onCancel}>
            Vazgeç
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default NewCategory;
