import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";
import {
  deleteProductGroup,
  getProductGroupList,
  saveProductGroup,
} from "../../infrastructure/filed_sets/product_price_api";
import newProductGroup from "../../infrastructure/filed_sets/NEW_PRODUCT_GROUP";
import ProductGroupForm from "./product_group_form";

const ProductGroupList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  let [filteredData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [fields, setFields] = useState(newProductGroup);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getProductGroupList().then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const addNew = () => {
    setFields(newProductGroup);
    setOpen(1);
  };

  const edit = (record) => {
    setFields([
      {
        name: ["name"],
        value: record.name,
      },
      {
        name: ["orderNo"],
        value: record.orderNo,
      },

      {
        name: ["id"],
        value: record.id,
      },
    ]);
    setOpen(1);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleOk = async (values) => {
    if (values.name === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newGroup = await saveProductGroup(values);

    await loadData();
    setConfirmLoading(false);

    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const handleDelete = async (value) => {
    console.log(value);
    await deleteProductGroup(value);
    await loadData();
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Sıra No",
      dataIndex: "orderNo",
      sorter: (a, b) => a.orderNo > b.orderNo,
      sortOrder: sortedInfo.columnKey === "orderNo" && sortedInfo.order,
    },
    {
      title: "Grup Açıklama",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    },

    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>

            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <Modal
        title="Grup Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ProductGroupForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>
      <Button onClick={addNew} type="primary">
        Stok Grup Kartı Ekle
      </Button>
      <Table
        columns={columns}
        dataSource={filteredData && filteredData.length ? filteredData : data}
        bordered
        loading={loading}
        rowKey="id"
      />
    </div>
  );
};

export default ProductGroupList;
