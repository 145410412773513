import React, { useEffect, useState } from "react";
import {
  deleteOptionItemSet,
  getOptionSetItemList,
  getPhotoByOptionSetItemRef,
  saveOptionItemSet,
} from "../../../infrastructure/kangaroo_api";

import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";
import { PictureOutlined, PictureFilled } from "@ant-design/icons";

import OptionSetItemForm from "./option_set_item_form";
import ImageToBase64Converter from "../../common/image_to_base_64_converter";

const OptionSetItemList = ({ optionSet, optionSetItems }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowKey, setEditRowKey] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [optionSetItemRef, setOptionSetItemRef] = useState(0);
  const [open, setOpen] = useState(0);
  const [icon, setIcon] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [fields, setFields] = useState([
    {
      name: ["setItemName"],
      value: "",
    },
    {
      name: ["price"],
      value: 0,
    },
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    setData(optionSetItems);
  }, [optionSetItems]);

  const loadData = async () => {
    console.log("LOADDATA");
    setLoading(true);
    getOptionSetItemList(optionSet).then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const showIcon = async (optionSetItem) => {
    setOptionSetItemRef(optionSetItem.id);

    var data = await getPhotoByOptionSetItemRef(optionSetItem);

    var d = data.find((element) => {
      return element.photoType === 0;
    });

    console.log(d);

    if (d !== undefined) {
      setIcon(d.data);
    } else {
      setIcon();
    }

    setOpen(2);
  };

  const showSelectedIcon = async (optionSetItem) => {
    setOptionSetItemRef(optionSetItem.id);
    var data = await getPhotoByOptionSetItemRef(optionSetItem);

    var d = data.find((element) => {
      return element.photoType === 1;
    });
    console.log(d);

    if (d !== undefined) {
      setSelectedIcon(d.data);
    } else {
      setSelectedIcon();
    }

    setOpen(3);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const isEditing = (record) => {
    return record.id == editRowKey;
  };

  const cancel = () => {
    setEditRowKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      record = { ...record, ...row, optionSetRef: optionSet.id };

      await saveOptionItemSet(record);
      await loadData();
    } catch {}
  };

  const addNew = () => {
    setFields([
      {
        name: ["setItemName"],
        value: "",
      },
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["price"],
        value: 0,
      },
    ]);
    setOpen(1);
  };

  const edit = (record) => {
    console.log(record);
    setFields([
      {
        name: ["setItemName"],
        value: record.setItemName,
      },
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["price"],
        value: record.price,
      },
    ]);
    setOpen(1);
  };

  const handleDelete = async (value) => {
    await deleteOptionItemSet(value);
    await loadData();
  };

  const handleOk = async (values) => {
    if (values.setItemName === undefined) {
      return;
    }
    setConfirmLoading(true);
    save({ ...values }).then((result) => {
      setConfirmLoading(false);
      setOpen(0);
    });
  };

  const handleCancel = (e) => {
    console.log("canceled");
    setOpen(0);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Opsiyon Adı",
      dataIndex: "setItemName",
      editable: true,
      sorter: (a, b) => a.setItemName.localeCompare(b.setItemName),
      sortOrder: sortedInfo.columnKey === "setItemName" && sortedInfo.order,
    },
    {
      title: "Fiyat",
      dataIndex: "price",
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary" disabled={editable}>
                Sil
              </Button>
            </Popconfirm>
            {editable ? (
              <span>
                <Space size="middle">
                  <Button
                    onClick={(e) => save(record)}
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    Kaydet
                  </Button>
                  <Popconfirm title="Vazgeç ? " onConfirm={cancel}>
                    <Button>Vazgeç</Button>
                  </Popconfirm>
                </Space>
              </span>
            ) : (
              <Button onClick={() => edit(record)} type="primary">
                Güncelle
              </Button>
            )}
            <Button onClick={() => showIcon(record)} type="default">
              {<PictureOutlined />}
            </Button>
            <Button onClick={() => showSelectedIcon(record)} type="default">
              {<PictureFilled />}
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <Modal
        title="Yeni Opsiyon Elamanı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <OptionSetItemForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>
      <Modal
        title="Seçilmemiş İkon"
        open={open == 2}
        footer={null}
        onCancel={() => {
          setIcon("");
          setSelectedIcon("");
          setOpen(0);
        }}
      >
        <ImageToBase64Converter
          photo={{
            data: icon,
            photoType: 0,
            optionSetItemRef: optionSetItemRef,
          }}
        />
      </Modal>
      <Modal
        title="Seçilmiş İkon"
        open={open == 3}
        footer={null}
        onCancel={() => {
          setIcon("");
          setSelectedIcon("");
          setOpen(0);
        }}
      >
        <ImageToBase64Converter
          photo={{
            data: selectedIcon,
            photoType: 1,
            optionSetItemRef: optionSetItemRef,
          }}
        />
      </Modal>
      <Space direction="vertical">
        {" "}
        <Button onClick={addNew} type="primary">
          Yeni Opsiyon Elemanı
        </Button>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          onChange={handleChange}
          rowKey="id"
        />
      </Space>
    </div>
  );
};

export default OptionSetItemList;
