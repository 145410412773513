import React, { useEffect, useState, useRef } from "react";
import { Button, Table, Space, DatePicker, Tag } from "antd";
import { getList } from "../../../infrastructure/kangaroo_api";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";

const SaleTotalReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rowCount, setRowCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalCount, setTotalCount] = useState(0.0);

  let startDateState = useRef();
  let endDateState = useRef();

  let [filteredData] = useState();
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    var newDate = dayjs(new Date());

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    startDateState.current = new Date(year, month, day).getTime();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();

    loadData(
      new Date(year, month, day).getTime(),
      new Date(year, month, day, 23, 59, 59).getTime()
    );
  }, []);

  const loadData = async (sDate, eDate) => {
    setLoading(true);

    getList(`report/sale/saleTotal/1/${sDate}/${eDate}`).then((result) => {
      var amount = 0.0;
      var total = 0.0;
      var recordCount = 0;

      result.map((el) => {
        let date = new Date(el.invoiceDate);
        el.invoiceDate =
          date.toLocaleDateString() + " - " + date.toLocaleTimeString();
        amount += el.amount;
        total += el.total;
        recordCount++;
      });

      setRowCount(recordCount);
      setTotalAmount(total);
      setTotalCount(amount);
      setData(result);
      setLoading(false);
    });
  };

  const globalSearch = async (e) => {
    await loadData(startDateState.current, endDateState.current);
    setData(data);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const details = (record) => {
    console.log(record);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Stok Kodu",
      dataIndex: "productCode",
      sorter: (a, b) => a.productCode.localeCompare(b.productCode),
      sortOrder: sortedInfo.columnKey === "productCode" && sortedInfo.order,
    },
    {
      title: "Stok Açıklama",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productCode),
      sortOrder: sortedInfo.columnKey === "productCode" && sortedInfo.order,
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <Space>
          <Tag color="green">{amount.toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.amount > b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
    },
    {
      title: "Ortalama Fiyat",
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Space>
          <Tag color="blue">{price.toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.price > b.price,
      sortOrder: sortedInfo.columnKey === "price" && sortedInfo.order,
    },
    {
      title: "Toplam Tutar",
      dataIndex: "total",
      align: "right",
      render: (total) => (
        <Space>
          <Tag color="orange">{total.toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.total > b.total,
      sortOrder: sortedInfo.columnKey === "total" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Button onClick={() => details(record)} type="primary">
              Detaylar
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const onStartDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    //setStartDate(new Date(year, month, day));
    startDateState.current = new Date(year, month, day).getTime();
  };

  const onEndDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();
  };

  return (
    <div>
      <Space>
        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(startDate)}
          onChange={onStartDateChange}
        />

        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(endDate)}
          onChange={onEndDateChange}
        />

        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag style={{ width: 120, textAlign: "center" }} color="red">
          ({rowCount})
        </Tag>

        <Tag style={{ width: 120, textAlign: "center" }} color="green">
          {totalAmount.toFixed(2)} (Toplam)
        </Tag>

        <Tag style={{ width: 120, textAlign: "center" }} color="orange">
          {totalCount.toFixed(2)} (Miktar)
        </Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};

export default SaleTotalReport;
