import React from "react";
import { Form, Input, Space, Button, InputNumber, Select } from "antd";
const { TextArea } = Input;

const ProductPriceForm = ({ onChange, fields, onCancel, onOK }) => {
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="product_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Form.Item
        name="productName"
        label="Stok Açıklama"
        rules={[
          {
            required: true,
            message: "Stok Açıklama alanı zorunludur!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="groupCode" label="Stok Grup">
        <Input />
      </Form.Item>
      <Form.Item name="unitName1" label="Birim (1)">
        <Input />
      </Form.Item>
      <Form.Item name="unitName2" label="Birim (2)">
        <Input />
      </Form.Item>
      <Form.Item name="unitName3" label="Birim (3)">
        <Input />
      </Form.Item>
      <Form.Item name="unitPrice1" label="Satış Fiyatı 1">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="unitPrice2" label="Satış Fiyatı 2">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="unitPrice3" label="Satış Fiyatı 3">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="orderNo" label="Sıra No">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="tvNumber" label="TV  (1-3)">
        <InputNumber min={1} />
      </Form.Item>

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default ProductPriceForm;
