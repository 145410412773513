import React, { useEffect, useState } from "react";
import { PictureFilled } from "@ant-design/icons";
import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";
import { del, getList, save } from "../../../infrastructure/kangaroo_api";
import ExtraForm from "./extra_form";
import newExtra from "../../../infrastructure/filed_sets/NEW_EXTRA";

const ExtraList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);

  const [fields, setFields] = useState(newExtra);

  let [filteredData] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getList("product/extra").then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleOk = async (values) => {
    if (values.extraCode === undefined) {
      return;
    }

    if (values.extraName === undefined) {
      return;
    }

    setConfirmLoading(true);
    await save("product/extra", values);
    await loadData();
    setConfirmLoading(false);

    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const addNew = async () => {
    setFields(newExtra);
    setOpen(1);
  };

  const edit = (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["extraCode"],
        value: record.extraCode,
      },
      {
        name: ["extraName"],
        value: record.extraName,
      },
      {
        name: ["price"],
        value: record.price,
      },
      {
        name: ["orderNo"],
        value: record.orderNo,
      },
    ]);

    setOpen(1);
  };

  const handleDelete = async (value) => {
    await del("product/extra", value);
    await loadData();
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Extra Kodu",
      dataIndex: "extraCode",
      sorter: (a, b) => a.extraCode.localeCompare(b.extraCode),
      sortOrder: sortedInfo.columnKey === "extraCode" && sortedInfo.order,
    },
    {
      title: "Extra Açıklama",
      dataIndex: "extraName",
      sorter: (a, b) => a.extraName.localeCompare(b.extraName),
      sortOrder: sortedInfo.columnKey === "extraName" && sortedInfo.order,
    },
    {
      title: "Fiyat",
      dataIndex: "price",
    },
    {
      title: "Sıra No",
      dataIndex: "orderNo",
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>

            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loadData();
    }
  };

  const globalSearch = (e) => {
    filteredData = data.filter((value) => {
      return value.extraName.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Extra Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ExtraForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Space style={{ marginBottom: 16, marginTop: 16 }}>
        <Button onClick={addNew} type="primary">
          Yeni Extra Kartı
        </Button>
        Filtre :
        <Input
          placeholder="Filtre"
          onChange={handleInputChange}
          type="text"
          allowClear
          value={searchText}
        />
        <Button onClick={globalSearch} type="primary">
          Ara
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={filteredData && filteredData.length ? filteredData : data}
          bordered
          loading={loading}
          onChange={handleChange}
          rowKey="id"
        />
      </Form>
    </div>
  );
};

export default ExtraList;
