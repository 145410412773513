import React, { useEffect, useState } from "react";
import { PictureFilled, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Space, Form, Input, Modal } from "antd";

import {
  del,
  getList,
  getPhotoByProductRef,
  save,
} from "../../../infrastructure/kangaroo_api";
import ProductForm from "./product_form";
import ImageToBase64Converter from "../../common/image_to_base_64_converter";
import ProductExtraList from "../product_extra/product_extra_list.jsx";
import newProduct from "../../../infrastructure/filed_sets/NEW_PRODUCT";

const ProductList = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [photo, setPhoto] = useState("");
  const [productRef, setProductRef] = useState(0);

  const [fields, setFields] = useState(newProduct);

  let [filteredData] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    getList("product/product").then((result) => {
      setData(result);
      setLoading(false);
    });
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleOk = async (values) => {
    if (values.productCode === undefined) {
      return;
    }

    if (values.productName === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newProduct = await save("product/product", values);
    console.log("New Product :" + newProduct);
    await loadData();
    setConfirmLoading(false);

    setOpen(0);
    //save({ ...values }).then((result) => {
    //  setConfirmLoading(false);
    //  setOpen(0);
    //});
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  /*
  const save = async (record) => {
    console.log(record);
    try {
      const row = await form.validateFields();
      setLoading(true);
      record = { ...record, ...row };
      await save("product", record);
      await loadData();
    } catch {}
  };*/

  const addNew = async () => {
    setFields(newProduct);

    var categories = await getList("product/category");
    var valueItems = categories.map(function (value) {
      return {
        value: value.id,
        label: value.categoryName,
      };
    });

    setCategories(valueItems);

    var departments = await getList("product/department");
    var depItems = departments.map(function (value) {
      return {
        value: value.id,
        label: value.departmentName,
      };
    });

    setDepartments(depItems);
    console.log(valueItems);
    setOpen(1);
  };

  const edit = async (record) => {
    var categories = await getList("product/category");
    var valueItems = categories.map(function (value) {
      return {
        value: value.id,
        label: value.categoryName,
      };
    });

    setCategories(valueItems);

    var departments = await getList("product/department");
    var depItems = departments.map(function (value) {
      return {
        value: value.id,
        label: value.departmentName,
      };
    });

    setDepartments(depItems);

    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["categoryRef"],
        value: record.categoryRef,
      },
      {
        name: ["productCode"],
        value: record.productCode,
      },
      {
        name: ["productName"],
        value: record.productName,
      },
      {
        name: ["description"],
        value: record.description,
      },
      {
        name: ["salePrice1"],
        value: record.salePrice1,
      },
      {
        name: ["salePrice2"],
        value: record.salePrice2,
      },
      {
        name: ["vatRate"],
        value: record.vatRate,
      },
      {
        name: ["departmentRef"],
        value: record.departmentRef,
      },
      {
        name: ["orderNo"],
        value: record.orderNo,
      },
    ]);

    setOpen(1);
  };

  const handleDelete = async (value) => {
    await del("product/product", value);
    await loadData();
  };

  const showPhotos = async (record) => {
    setProductRef(record.id);
    var data = await getPhotoByProductRef(record);

    if (data[0] !== undefined) {
      setPhoto(data[0].data);
    } else {
      setPhoto();
    }

    setOpen(2);
  };

  const showExtras = (record) => {
    setProductRef(record.id);
    setOpen(3);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "left",
    },
    {
      title: "Stok Kodu",
      dataIndex: "productCode",
      sorter: (a, b) => a.productCode.localeCompare(b.productCode),
      sortOrder: sortedInfo.columnKey === "productCode" && sortedInfo.order,
    },
    {
      title: "Stok Açıklama",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === "productName" && sortedInfo.order,
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
    },
    {
      title: "Satış Fiyat 1",
      dataIndex: "salePrice1",
    },
    {
      title: "Satış Fiyat 2",
      dataIndex: "salePrice2",
    },
    {
      title: "Sıra No",
      dataIndex: "orderNo",
    },

    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>

            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
            <Button onClick={() => showPhotos(record)} type="default">
              {<PictureFilled />}
            </Button>
            <Button onClick={() => showExtras(record)} type="default">
              {<UnorderedListOutlined />}
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      loadData();
    }
  };

  const globalSearch = (e) => {
    filteredData = data.filter((value) => {
      return value.productName.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Stok Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ProductForm
          categoryItems={categories}
          departments={departments}
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Modal
        title="Fotoğraf"
        open={open == 2}
        footer={null}
        onCancel={() => {
          setPhoto("");
          setOpen(0);
        }}
      >
        <ImageToBase64Converter
          photo={{
            data: photo,
            photoType: 0,
            productRef: productRef,
          }}
        />
      </Modal>

      <Modal
        title="Extra Listesi"
        open={open == 3}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <ProductExtraList productRef={productRef} />
      </Modal>

      <Space style={{ marginBottom: 16, marginTop: 16 }}>
        <Button onClick={addNew} type="primary">
          Yeni Stok Kartı
        </Button>
        Filtre :
        <Input
          placeholder="Filtre"
          onChange={handleInputChange}
          type="text"
          allowClear
          value={searchText}
        />
        <Button onClick={globalSearch} type="primary">
          Ara
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={filteredData && filteredData.length ? filteredData : data}
          bordered
          loading={loading}
          onChange={handleChange}
          rowKey="id"
        />
      </Form>
    </div>
  );
};

export default ProductList;
