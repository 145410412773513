import axios from "axios";
const PRODUCT_PRICE_API_BASE_URL = "https://server.gurkanucar.com/kangarootv";

const product_price_instance = axios.create({
  baseURL: PRODUCT_PRICE_API_BASE_URL,
});

product_price_instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

export const getProductPriceList = async () => {
  const response = await product_price_instance.get("/price");
  return response.data;
};

export const deleteProductPriceList = async (item) => {
  const response = await product_price_instance.delete(`/price/${item.id}`);
  console.log(response);
  return response.data;
};

export const saveProductPrice = async (item) => {
  const response = await product_price_instance.post("/price", item);
  return response.data;
};

export const getProductGroupList = async () => {
  const response = await product_price_instance.get("/productGroup");
  return response.data;
};

export const deleteProductGroup = async (item) => {
  const response = await product_price_instance.delete(
    `/productGroup/${item.id}`
  );
  console.log(response);
  return response.data;
};

export const saveProductGroup = async (item) => {
  const response = await product_price_instance.post("/productGroup", item);
  return response.data;
};
