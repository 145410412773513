const newProductGroup = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["name"],
    value: "",
  },
  {
    name: ["orderNo"],
    value: 0,
  },
];

export default newProductGroup;
