const newExtra = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["extraCode"],
    value: "",
  },
  {
    name: ["extraName"],
    value: "",
  },
  {
    name: ["price"],
    value: 0,
  },
  {
    name: ["orderNo"],
    value: 0,
  },
];

export default newExtra;
